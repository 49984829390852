@import url("../../app/globals.css");

.location-title__cont:hover .location-data__title {
  color: var(--green)
}

.location-title__cont:hover .location-data__icon {
  fill: var(--green)
}

.social-media-icon__cont:hover{
  background-color: var(--lowOpacityGreen);
}