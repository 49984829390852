@import url("../../../app/globals.css");

.arrow-active {
  transform: rotate(90deg);
}

@media(min-width: 1280px) {
  .question-answer-cont:hover .question-rectangle {
    background-color: var(--lowOpacityWhite);
  }
}