@import url("../../app/globals.css");

.mobile-default-link__cont:hover {
  color: var(--green)
}

.mobile-nested-link__cont:focus .arrow-icon {
  transform: rotate(90deg);
}

.mobile-nested-link__label-cont:active .arrow-icon {
  fill: var(--green);
}

.mobile-nested-link__label-cont:active .mobile-nested-link__label {
  color: var(--green);
}

.mobile-nested-link__cont:focus .mobile-nested-link-list {
  display: block
}

.mobile-nested-link-list {
  transition: all 300ms ease-in-out;
}

.nav-link__cont {
  transition: all 200ms ease;
}

.nav-link__cont:hover .nav-link-inner-cont {
  color:white;
}

.nav-link:hover {
  color:white;
}

.nav-link__cont:hover .arrow-icon {
  transform: rotate(90deg);
}

.arrow-icon {
  transition: all 200ms ease-in;
}

.drawer-links-cont:hover .blurred-drawer-bg {
  height: 100vh;
  bottom: 100%;
}

.drawer-links-cont:hover ~ .drawer-bg {
  display: block;
  opacity: 1;
}

.drawer {
  transition: all 400ms ease-in-out;
}

.nav-link__cont:hover .drawer {
  bottom: -331px;
  height: 330px;
  opacity: 1;
}

.nav-link__cont:hover .main-section {
  filter: blur(12px);
}

.nav-link__cont:hover .drawer-list {
  z-index: 3;
  display: flex;
}

.drawer:hover .drawer-list {
  z-index: 3;
  display: flex;
}

.register-nav-section__cont:hover .register-nav-icon {
  background-color: var(--lowOpacityWhite);
}

.log-and-sign-buttons__drawer {
  transition: bottom 200ms ease-in-out;
}

.register-nav-section__cont:hover .log-and-sign-buttons__drawer {
  bottom: -107px;
}