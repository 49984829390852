@import url("../../../app/globals.css");

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos-carousel {
  width: 100vw;
  overflow: hidden;
  white-space: nowrap;
  height: 102px;
}

.logos-carousel__slide__cont {
  width: 5721px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logos-carousel__slide {
  /* this property depends on the amount of logos that the slide has and the width of them, currently there are 8 logos of
    80px each */
  display: flex;
  align-items: center;
  margin: auto 0;
  height: 60px;
  width: 1907px;
  animation: 40s slide infinite linear;
}

#extra-carousel-slide {
  display: none;
}

.carousel-item {
  display: inline-block;
  /* this prop is the ratio between the 100% of width and the amount of itmes that eache carousel slide has */
  width: 85px;
  height: 65px;
  margin: 0 40px;
}

#logo-villalobos {
  width: 130px;
  height: 130px;
}

#logo-animal-center {
  width: 142px;
  height: 38px
}

#logo-unidad-renal {
  width: 75px;
  height: 45px;
}

@media (min-width: 1536px) {
  .logos-carousel {
    height: 128px;
    padding: 14px 0;
  }

  .logos-carousel__slide__cont {
    width: 7902px;
  }

  .logos-carousel__slide {
    width: 2634px;
    height: 80px;
  }

  .carousel-item {
    width: 110px;
    height: 100px;
    margin: 0 60px;
  }

  #logo-villalobos {
    width: 150px;
    height: 180px;
  }

  #logo-animal-center {
    width: 188px;
    height: 50px
  }

  #logo-unidad-renal {
    width: 96px;
    height: 55px;
  }
}

@media (min-width: 2000px) {
  #extra-carousel-slide {
    display: flex;
  }
}
